import { i18n } from '@/services/i18n.service'
import deepmerge from 'deepmerge'
import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import App from './App.vue'
import { firebasePlugin } from './plugins/firebase'
import router from './router'
import { getCookie } from './services/cookie.service'
import { vuetifyConfig } from './vuetifyConfig'

Vue.config.productionTip = false

Vue.use(Vuetify)

const darkCookie = getCookie('dark')
const dark = darkCookie === 'true' || darkCookie === undefined

const currentVuetifyConfig = {
  theme: {
    dark,
  },
}

let app: Vue | null = null
function initVueApp() {
  if (!app) {
    app = new Vue({
      router,
      i18n,
      vuetify: new Vuetify({ ...deepmerge(vuetifyConfig, currentVuetifyConfig) }),
      render: (h) => h(App),
    }).$mount('#app')
  }
}

// We initialize the Vue app when we have firebase ready
Vue.use(firebasePlugin, initVueApp)
