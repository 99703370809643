import { User } from 'firebase/auth'
import Vue from 'vue'

const state = Vue.observable({
  currentSteelspaceUser: null as null | User,
  loading: false,
  error: '',
})

export const setAuthError = (value: string): void => {
  state.error = value
}

export const setSteelspaceCurrentUser = (value: User | null): void => {
  state.currentSteelspaceUser = value
}

export const setAuthLoading = (value: boolean): void => {
  state.loading = value
}

export const authStore = state as Readonly<typeof state>
