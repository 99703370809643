import { createScreenshotAsync } from '@/store/modelViewer.store'
import { getApp } from '@firebase/app'
import {
  DocumentData,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  where,
} from '@firebase/firestore'
import {
  getModelFromCache,
  saveModelPreviewUrlToCache,
  saveModelToCache,
} from '../../services/cache.service'
import { SteelspaceFileResponse, SteelspaceResponse } from '../../types'
import { authStore } from '../auth'
import {
  modelStore,
  setModel,
  setModelError,
  setModelLoading,
  setModelUnSubs,
  setModels,
  setPublicModels,
} from './model.store'
import {
  addShareRequest,
  deleteModelRequest,
  exportMaterialListRequest,
  getDownloadLinkToModelFileRequest,
  getModelFileRequest,
  getModelPreviewUrlRequest,
  modifyShareRequest,
  moveModelRequest,
  removeShareRequest,
  updateModelPreviewRequest,
  uploadModelFileRequest,
} from './requests'
import {
  SteelspaceModel,
  SteelspaceModelDownloadLinkResponse,
  SteelspaceModelRelatedFile,
  SteelspaceModelUserShare,
} from './types'

/////////////////////////
/////REQUEST CALLS///////
/////////////////////////

////////////SHARE REQUESTS////////////
export const addShare = async (
  modelId: string,
  share: SteelspaceModelUserShare
): Promise<SteelspaceResponse | undefined> => {
  setModelLoading(true)
  const response = await addShareRequest(modelId, share)
  setModelLoading(false)
  return response
}

export const modifyShare = async (
  modelId: string,
  share: SteelspaceModelUserShare
): Promise<SteelspaceResponse | undefined> => {
  setModelLoading(true)
  const response = await modifyShareRequest(modelId, share)
  setModelLoading(false)
  return response
}
export const removeShare = async (
  modelId: string,
  share: SteelspaceModelUserShare
): Promise<SteelspaceResponse | undefined> => {
  setModelLoading(true)
  const response = await removeShareRequest(modelId, share)
  setModelLoading(false)
  return response
}

//////////////MODEL REQEUSTS///////////
export const moveModel = async (
  modelId: string,
  newParentId: string
): Promise<SteelspaceResponse | undefined> => {
  setModelLoading(true)
  const response = await moveModelRequest(modelId, newParentId)
  setModelLoading(false)
  return response
}

export const deleteModel = async (modelId: string): Promise<SteelspaceResponse | undefined> => {
  setModelLoading(true)
  const response = await deleteModelRequest(modelId)
  setModelLoading(false)
  return response
}

export const getDownloadLinkToModelFile = async (
  modelId: string
): Promise<SteelspaceModelDownloadLinkResponse | undefined> => {
  setModelLoading(true)
  const response = await getDownloadLinkToModelFileRequest(modelId)
  setModelLoading(false)
  return response
}

export const getModelPreviewUrl = async (modelId: string): Promise<string | undefined> => {
  setModelLoading(true)
  const response = await getModelPreviewUrlRequest(modelId)
  setModelLoading(false)

  return response?.url
}

export const getModelFile = async (
  modelId: string,
  lastModificationDate: number
): Promise<Blob | undefined> => {
  setModelLoading(true)
  let modelFile: SteelspaceFileResponse | undefined
  //we need to wrap this, firefox doesn't let us read IDB if in incognito
  try {
    const cached = await getModelFromCache(modelId, lastModificationDate)

    if (!cached) {
      //not in cache, dowlnload needed
      modelFile = await getModelFileRequest(modelId)
      if (!modelFile) return undefined

      await saveModelToCache(modelId, lastModificationDate, modelFile.file)
    } else {
      modelFile = cached
    }
    //if an error occurs, just download the model
  } catch {
    modelFile = await getModelFileRequest(modelId)
  }

  setModelLoading(false)
  return modelFile?.file
}

export const updateModelPreview = async (
  modelId: string,
  preview: File
): Promise<SteelspaceResponse | undefined> => {
  setModelLoading(true)
  try {
    const response = await updateModelPreviewRequest(modelId, preview)
    setModelLoading(false)
    return response
  } catch (error) {
    setModelLoading(false)
    if (error instanceof Error) setModelError(error.message)
  }
}

export const uploadModelFile = async (
  name: string,
  description: string,
  tags: string[],
  parentId: string,
  softwareVersion: string,
  files: File[]
): Promise<SteelspaceResponse | undefined> => {
  setModelLoading(true)

  try {
    const response = await uploadModelFileRequest(
      name,
      description,
      tags,
      parentId,
      softwareVersion,
      files
    )

    setModelLoading(false)
    return response
  } catch (error) {
    setModelLoading(false)
    if (error instanceof Error) setModelError(error.message)
  }
}

export const exportMaterialList = async (model: SteelspaceModel): Promise<void> => {
  setModelLoading(true)
  await exportMaterialListRequest(model)
  setModelLoading(false)
}

/////////////////////////
/////FIREBASE SDK//////
////////////////////////
const getModelDocumentById = async (modelId: string): Promise<DocumentData | undefined> => {
  setModelLoading(true)
  const modelDocument = (await getDoc(doc(getFirestore(getApp()), 'models', modelId))).data()
  setModelLoading(false)

  return modelDocument
}

export const getUserModelsSubscribe = (): SteelspaceModel[] => {
  if (!modelStore.models.length) subscribeToModels()

  return modelStore.models
}

export const getPublicModels = (): SteelspaceModel[] | null => {
  if (!modelStore.publicModels?.length) getPublicModelsQuery()

  return modelStore.publicModels
}

export const getModelByIdAsync = async (id?: string): Promise<SteelspaceModel> => {
  if ((!modelStore.model && id) || (id && modelStore.model && modelStore.model.id !== id))
    await getModelDocumentById(id)

  return modelStore.model || new SteelspaceModel()
}

export const getModelByIdSubscribe = (id?: string): SteelspaceModel | null => {
  if ((!modelStore.model && id) || (id && modelStore.model && modelStore.model.id !== id))
    subscribeToModel(id)

  return modelStore.model
}

const getPublicModelsQuery = (): void => {
  setModelLoading(true)
  const queryPublicModels = query(
    collection(getFirestore(getApp()), 'models'),
    where('isPublic', '==', true)
  )

  getDocs(queryPublicModels).then((docs) => {
    setPublicModels([])
    docs.forEach((doc) => {
      const data = doc.data()
      modelStore.publicModels?.push(new SteelspaceModel(data))
    })
    setModelLoading(false)
  })
}

const subscribeToModel = (modelId: string): void => {
  const unsubKey = 'model'

  modelStore.unSubs[unsubKey]?.()

  const modelUnsub = onSnapshot(
    doc(getFirestore(getApp()), 'models', modelId),
    (doc) => {
      setModel(new SteelspaceModel(doc.data()))
    },
    (error) => {
      console.log(error.code)
    }
  )

  modelStore.unSubs[unsubKey] = modelUnsub
}

const subscribeToModels = (): void => {
  const unsubKey = 'models'
  if (modelStore.unSubs[unsubKey] || !authStore.currentSteelspaceUser) {
    return
  }

  const queryModels = query(
    collection(getFirestore(getApp()), 'models'),
    where('userData.id', '==', authStore.currentSteelspaceUser?.uid)
  )

  const unsubFromModelsUpdates = onSnapshot(queryModels, async (querySnapshot) => {
    setModelLoading(true)

    const promises = querySnapshot.docs.map(async (doc) => {
      const model = new SteelspaceModel(doc.data())
      const relatedFiles = await getDocs(collection(doc.ref, 'modelFiles'))
      relatedFiles.docs.map((related) =>
        model.relatedFiles.push(related.data() as SteelspaceModelRelatedFile)
      )
      return model
    })

    const models = await Promise.all(promises)
    setModels(models)
    setModelLoading(false)
  })

  modelStore.unSubs[unsubKey] = unsubFromModelsUpdates
}

export const unSubFromModelFirebaseSnapshots = (): void => {
  Object.values(modelStore.unSubs).forEach((unsub) => {
    unsub()
  })

  setModelUnSubs({})
}

////////////////////
/////OTHERS////////
///////////////////

export const getModelChecksum = (modelId: string, steelspaceModels: SteelspaceModel[]): string => {
  return (
    steelspaceModels
      .find((model) => model.id === modelId)
      ?.relatedFiles.find((file) => file.extension === 'smadsteel')?.checksum || ''
  )
}

export const createModelPreview = async (currentModel: SteelspaceModel): Promise<void> => {
  const imgStr64FullUrl = await createScreenshotAsync('preview')

  if (!imgStr64FullUrl) return

  const imgStr = imgStr64FullUrl.split(',')[1]
  const imgFile = new File([Buffer.from(imgStr, 'base64')], 'preview.png', {
    type: 'image/png',
  })

  await updateModelPreview(currentModel.id, imgFile)

  const previewUrl = await getModelPreviewUrl(currentModel.id)

  if (!previewUrl) return

  const model = modelStore.models.find((model) => model.id === currentModel.id)

  if (!model) return

  model.previewUrl = previewUrl

  saveModelPreviewUrlToCache(model)
}
