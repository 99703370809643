import { formatSize } from '../../../services'
import { SteelspaceModel } from '../../model/types'
import { SteelspaceFolder } from './folder.model'

export class HydratedSteelspaceFolder extends SteelspaceFolder {
  childFolders!: HydratedSteelspaceFolder[]
  childModels!: SteelspaceModel[]

  clone(): HydratedSteelspaceFolder {
    return new HydratedSteelspaceFolder({
      childFolders: this.childFolders,
      childModels: this.childModels,
      id: this.id,
      creationDate: this.creationDate,
      lastOpenDate: this.lastOpenDate,
      name: this.name,
      type: this.type,
      description: this.description,
      size: this.size,
      displaySize: this.displaySize,
      isRoot: this.isRoot,
      parentId: this.parentId,
      parentName: this.parentName,
      userData: this.userData,
    })
  }

  constructor(obj?: Record<string, unknown>) {
    super(obj)
    if (obj) {
      Object.assign(this, obj)
    }

    this.displaySize = formatSize(this.size)
  }
}
