
import { getSurfaceLoad } from '@/modules/storm'
import { latex_units, roundToTwoDigits } from '@/services/unit.service'
import { SurfaceLoad } from '@consteel/storm'
import DefaultLabel from './DefaultLabel.vue'
import { LabelBase, LabelContent } from './LabelBase'

export default (DefaultLabel as typeof LabelBase).extend({
  name: 'SurfaceLoadLabel',
  computed: {
    currentMember(): SurfaceLoad | null {
      return getSurfaceLoad(this.model, this.objectGuid)
    },
    title(): string | undefined {
      return this.currentMember?.name
    },
    multiplicationFactor(): number {
      return this.currentMember?.multiplicationFactor || 1
    },
    content(): LabelContent[] {
      return [
        {
          name: 'x',
          value: roundToTwoDigits(
            this.currentMember?.uniformForce?.x
              ? this.currentMember.uniformForce.x * this.multiplicationFactor
              : 0
          ),
          unit: latex_units.kn_m2,
        },
        {
          name: 'y',
          value: roundToTwoDigits(
            this.currentMember?.uniformForce?.y
              ? this.currentMember.uniformForce.y * this.multiplicationFactor
              : 0
          ),
          unit: latex_units.kn_m2,
        },
        {
          name: 'z',
          value: roundToTwoDigits(
            this.currentMember?.uniformForce?.z
              ? this.currentMember.uniformForce.z * this.multiplicationFactor
              : 0
          ),
          unit: latex_units.kn_m2,
        },
      ]
    },
  },
})
