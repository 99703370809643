import { CacheContainer } from '@consteel/storm'
import Vue from 'vue'

const state = Vue.observable({
  cache: new CacheContainer(),
  secondaryCache: new CacheContainer(),
})

export const clearCache = (): void => {
  state.cache.Clear()
}

export const clearSecondaryCache = (): void => {
  state.secondaryCache.Clear()
}

export const stormStore = state as Readonly<typeof state>
