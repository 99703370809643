import { getApp } from '@firebase/app'
import { collection, getFirestore, onSnapshot, query, where } from '@firebase/firestore'
import { SteelspaceResponse } from '../../types'
import { authStore } from '../auth'
import { folderStore, setFolderLoading, setFolderUnSubs, setFolders } from './folder.store'
import { createFolderRequest, deleteFolderRequest, moveFolderRequest } from './requests'
import { SteelspaceFolder } from './types/folder.model'

/////////////////////////
/////REQUEST CALLS///////
/////////////////////////
export const createFolder = async (
  name: string,
  parentId: string
): Promise<SteelspaceResponse | undefined> => {
  setFolderLoading(true)
  const response = await createFolderRequest(name, parentId)
  setFolderLoading(false)

  return response
}

export const deleteFolder = async (folderId: string): Promise<SteelspaceResponse | undefined> => {
  setFolderLoading(true)
  const response = await deleteFolderRequest(folderId)
  setFolderLoading(false)

  return response
}

export const moveFolder = async (
  folderId: string,
  moveTarget: string
): Promise<SteelspaceResponse | undefined> => {
  setFolderLoading(true)
  const response = await moveFolderRequest(folderId, moveTarget)
  setFolderLoading(false)

  return response
}

/////////////////////////
/////FIREBASE SDK//////
////////////////////////
export const getUserFoldersSubscribe = (): SteelspaceFolder[] => {
  if (!folderStore.folders.length) subscribeToFolders()

  return folderStore.folders
}

const subscribeToFolders = (): void => {
  const unsubKey = 'folders'

  if (folderStore.unSubs[unsubKey] || !authStore.currentSteelspaceUser) {
    return
  }

  const queryFolders = query(
    collection(getFirestore(getApp()), 'folders'),
    where('userData.id', '==', authStore.currentSteelspaceUser?.uid)
  )
  const unsubFromFolderUpdates = onSnapshot(queryFolders, (querySnapshot) => {
    setFolders([])
    setFolderLoading(true)

    querySnapshot.forEach((doc) => {
      folderStore.folders.push(new SteelspaceFolder(doc.data()))
    })

    setFolderLoading(false)
  })

  folderStore.unSubs[unsubKey] = unsubFromFolderUpdates
}

export const unSubFromFolderFirebaseSnapshots = (): void => {
  Object.values(folderStore.unSubs).forEach((unsub) => {
    unsub()
  })

  setFolderUnSubs({})
}
