import { SteelspaceModel } from '../types/'
import { doRequest, downloadFile } from '../../../services/requests'
import {
  SteelspaceFileResponse,
  SteelspacePreviewResponse,
  SteelspaceResponse,
} from '../../../types'
import { MoveModelDto, SteelspaceModelDownloadLinkResponse } from '../types'

export const moveModelRequest = async (
  modelId: string,
  newParentId: string
): Promise<SteelspaceResponse | undefined> => {
  const dto = new MoveModelDto(newParentId)
  const response = await doRequest(`v2/users/me/models/${modelId}`, 'PUT', dto)
  const responseJson: SteelspaceResponse = await response.json()
  if (responseJson.statusCode === 200) {
    return responseJson
  } else {
    return undefined
  }
}

export const deleteModelRequest = async (
  modelId: string
): Promise<SteelspaceResponse | undefined> => {
  const response = await doRequest(`v2/users/me/models/${modelId}`, 'DELETE')
  const responseJson: SteelspaceResponse = await response.json()

  if (responseJson.statusCode === 200) {
    return responseJson
  } else {
    return undefined
  }
}

export const getDownloadLinkToModelFileRequest = async (
  modelId: string
): Promise<SteelspaceModelDownloadLinkResponse | undefined> => {
  const response = await doRequest(`v2/users/me/models/${modelId}/download`, 'GET')
  const responseJson: SteelspaceResponse = await response.json()
  if (responseJson.statusCode === 200) {
    const result: SteelspaceModelDownloadLinkResponse = <{ url: string }>responseJson.payload
    return result
  } else {
    return undefined
  }
}

export const getModelFileRequest = async (
  modelId: string
): Promise<SteelspaceFileResponse | undefined> => {
  const getDownloadLinkResponse = await getDownloadLinkToModelFileRequest(modelId)
  if (!getDownloadLinkResponse) return undefined
  const request = async () => {
    return await fetch(getDownloadLinkResponse.url, {
      method: 'GET',
      mode: 'cors',
    })
  }
  const response = await request()
  if (!response.ok) return undefined
  const buffer = await response.arrayBuffer()
  const result: SteelspaceFileResponse = {
    file: new Blob([buffer], { type: 'application/octet-stream' }),
  }
  return result
}

export const getModelPreviewUrlRequest = async (
  modelId: string
): Promise<SteelspacePreviewResponse | undefined> => {
  const response = await doRequest(`v2/users/me/models/${modelId}/preview`, 'GET')
  const responseJson: SteelspaceResponse = await response.json()
  if (responseJson.statusCode === 200) {
    return (<{ url: string }>responseJson.payload) as SteelspacePreviewResponse
  } else {
    return undefined
  }
}

export const updateModelPreviewRequest = async (
  modelId: string,
  preview: File
): Promise<SteelspaceResponse | undefined> => {
  const formData = new FormData()
  formData.append('Preview', preview)

  const response = await doRequest(
    `update/${modelId}/preview`,
    'POST',
    formData,
    process.env.VUE_APP_CLOUD_SAVE_API_BASEURL,
    '2.0',
    false
  )

  const responseJson: SteelspaceResponse = await response.json()
  if (responseJson.statusCode === 200) {
    return responseJson
  } else {
    if (responseJson.error) {
      const errorMessage =
        (responseJson.error.message as string) || (responseJson.error.error as string)
      throw new Error(errorMessage)
    }
  }
}

export const uploadModelFileRequest = async (
  name: string,
  description: string,
  tags: string[],
  parentId: string,
  softwareVersion: string,
  files: File[]
): Promise<SteelspaceResponse | undefined> => {
  const formData = new FormData()
  formData.append('Name', name)
  formData.append('Description', description)
  formData.append('ParentId', parentId)
  formData.append('SoftwareVersion', softwareVersion)
  tags.forEach((tag) => formData.append('Tags[]', tag))
  files.forEach((modelFile) => formData.append('Files', modelFile))

  const response = await doRequest(
    `upload`,
    'POST',
    formData,
    process.env.VUE_APP_CLOUD_SAVE_API_BASEURL,
    '2.0',
    false
  )

  const responseJson: SteelspaceResponse = await response.json()
  if (responseJson.statusCode === 200) {
    return responseJson
  } else {
    if (responseJson.error) {
      const errorMessage =
        (responseJson.error.message as string) || (responseJson.error.error as string)
      throw new Error(errorMessage)
    }
  }
}

export const exportMaterialListRequest = async (
  model: SteelspaceModel
): Promise<void | undefined> => {
  const response = await doRequest(
    `export/${model.id}/excel`,
    'GET',
    undefined,
    process.env.VUE_APP_CLOUD_SAVE_API_BASEURL,
    '2.0',
    false
  )

  if (response.ok) {
    downloadFile(await response.blob(), `material_list_${model.name}.xlsx`)
  }
}
