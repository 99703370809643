export const getLicencingToken = async (
  authToken: string,
  clientId = process.env.VUE_APP_FB_PROJECTID
): Promise<string> => {
  const response = await fetch(
    `${process.env.VUE_APP_FB_CONSTEEL_LICENCING_BASEURL}/api/v2/oauth/authorize`,
    {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ clientId: clientId }),
    }
  )
  const responseJson: string = (await response.json()).payload.token
  return responseJson
}

export const getCustomTokenOauth = async (token: string): Promise<string | null> => {
  const response = await fetch(`${process.env.VUE_APP_FB_API_BASEURL}/v2/oauth/token`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ token: token }),
  })
  if (response.status != 200) {
    return null
  }
  const responseJson: string = (await response.json()).payload.customToken
  return responseJson
}

export const generateCustomToken = async (idToken: string): Promise<string | null> => {
  const response = await fetch(`${process.env.VUE_APP_FB_API_BASEURL}/v2/auth/custom-token`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  })
  if (response.status != 200) {
    return null
  }
  const responseJson: string = (await response.json()).payload.customToken
  return responseJson
}
