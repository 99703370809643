import { deleteModelFromCache } from '@/services/cache.service'
import { SteelspaceModel, SteelspaceModelDownloadLinkResponse } from '../../../modules/model/types'
import { doRequest, downloadFile } from '../../../services/requests/Base'
import {
  SteelspaceFileResponse,
  SteelspacePreviewResponse,
  SteelspaceResponse,
} from '../../../types'
import {
  CompareHistoryItemsDto,
  CompareOptions,
  CompareResult,
  CreateHistoryDto,
  EditHistoryDto,
  HistoryItem,
  HistoryItemFile,
} from '../types'

export const getModelHistoryRequest = async (
  modelId: string
): Promise<HistoryItem[] | undefined> => {
  const response = await doRequest(`v2/users/me/models/${modelId}/history`, 'GET')
  const responseJson: SteelspaceResponse = await response.json()

  if (responseJson.statusCode === 200) {
    return (<{ history: HistoryItem[] }>(responseJson.payload as unknown)).history as HistoryItem[]
  } else {
    return undefined
  }
}

export const getHistoryItemRequest = async (
  modelId: string,
  historyId: string
): Promise<HistoryItem | undefined> => {
  const response = await doRequest(`v2/users/me/models/${modelId}/history/${historyId}`, 'GET')
  const responseJson: SteelspaceResponse = await response.json()

  if (responseJson.statusCode === 200) {
    return (<{ history: HistoryItem }>(responseJson.payload as unknown)).history as HistoryItem
  } else {
    return undefined
  }
}

export const getModelHistoryFilesRequest = async (
  modelId: string,
  historyId: string
): Promise<HistoryItemFile[] | undefined> => {
  const response = await doRequest(
    `v2/users/me/models/${modelId}/history/${historyId}/files`,
    'GET'
  )
  const responseJson: SteelspaceResponse = await response.json()

  if (responseJson.statusCode === 200) {
    return (<{ files: HistoryItemFile[] }>(responseJson.payload as unknown))
      .files as HistoryItemFile[]
  } else {
    return undefined
  }
}

export const getHistoryPreviewUrlRequest = async (
  modelId: string,
  historyId: string
): Promise<SteelspacePreviewResponse | undefined> => {
  const response = await doRequest(
    `v2/users/me/models/${modelId}/history/${historyId}/preview`,
    'GET'
  )
  const responseJson: SteelspaceResponse = await response.json()
  if (responseJson.statusCode === 200) {
    return (<{ url: string }>responseJson.payload) as SteelspacePreviewResponse
  } else {
    return undefined
  }
}

export const downloadHistoryItemFileRequest = async (historyItem: HistoryItem): Promise<void> => {
  const csmFile = historyItem.historyItemFiles?.find((file) => file.extension === 'csm')
  const csmFileName = `${historyItem.title}.${csmFile?.extension}`

  if (!csmFile) {
    console.error('CSM File was not found')
    return
  }

  const result = await getDownloadLinkToHistoryFileRequest(
    historyItem.modelId,
    historyItem.id,
    csmFile.id
  )

  if (result?.url) {
    const res = await fetch(result.url as string, { method: 'GET', mode: 'cors' })

    downloadFile(await res.blob(), csmFileName)
  }
}

export const createHistoryItemRequest = async (
  model: SteelspaceModel,
  title: string,
  description: string
): Promise<HistoryItem | undefined> => {
  const dto = new CreateHistoryDto(model.id, title, description)

  const response = await doRequest(`v2/users/me/models/${model.id}/history`, 'POST', dto)

  const responseJson: SteelspaceResponse = await response.json()
  if (responseJson.statusCode === 201) {
    const result: HistoryItem = <HistoryItem>responseJson.payload.history

    return new HistoryItem(result)
  } else {
    if (responseJson.error && (responseJson.error.message as string)) {
      throw new Error(responseJson.error.message as string)
    }
  }
}

export const editHistoryItemRequest = async (
  historyItem: HistoryItem
): Promise<HistoryItem | undefined> => {
  const dto = new EditHistoryDto(historyItem.title, historyItem.description)

  const response = await doRequest(
    `v2/users/me/models/${historyItem.modelId}/history/${historyItem.id}`,
    'PUT',
    dto
  )

  const responseJson: SteelspaceResponse = await response.json()

  if (responseJson.statusCode === 200) {
    return new HistoryItem(responseJson.payload)
  } else {
    if (responseJson.error && (responseJson.error.message as string)) {
      throw new Error(responseJson.error.message as string)
    }
  }
}

export const deleteHistoryItemRequest = async (
  historyItem: HistoryItem
): Promise<boolean | undefined> => {
  const response = await doRequest(
    `v2/users/me/models/${historyItem.modelId}/history/${historyItem.id}`,
    'DELETE'
  )

  const responseJson: SteelspaceResponse = await response.json()

  if (responseJson.statusCode === 200) {
    return true
  } else {
    if (responseJson.error && (responseJson.error.message as string)) {
      throw new Error(responseJson.error.message as string)
    }
  }
}

export const restoreHistoryItemRequest = async (
  historyItem: HistoryItem
): Promise<HistoryItem | undefined> => {
  const response = await doRequest(
    `v2/users/me/models/${historyItem.modelId}/history/${historyItem.id}/restore`,
    'POST'
  )

  const responseJson: SteelspaceResponse = await response.json()

  if (responseJson.statusCode === 200) {
    const result = responseJson.payload

    deleteModelFromCache(historyItem.modelId)
    return new HistoryItem(result)
  } else {
    if (responseJson.error && (responseJson.error.message as string)) {
      throw new Error(responseJson.error.message as string)
    }
  }
}

export const compareHistoryItemsRequest = async (
  modelId: string,
  sourceHistoryItemId: string,
  targetHistoryItemId: string,
  options: CompareOptions
): Promise<CompareResult | undefined> => {
  const dto = new CompareHistoryItemsDto(options)

  const response = await doRequest(
    `api/compare/model/${modelId}/history?sourceId=${sourceHistoryItemId}&targetId=${targetHistoryItemId}`,
    'POST',
    dto,
    process.env.VUE_APP_COMPARE_API_BASEURL
  )

  const responseJson: SteelspaceResponse = await response.json()
  if (responseJson.statusCode === 200) {
    return responseJson.payload as CompareResult
  } else {
    return undefined
  }
}

export const getDownloadLinkToHistoryFileRequest = async (
  modelId: string,
  historyId: string,
  fileId: string
): Promise<SteelspaceModelDownloadLinkResponse | undefined> => {
  const response = await doRequest(
    `v2/users/me/models/${modelId}/history/${historyId}/files/${fileId}/download`,
    'GET'
  )
  const responseJson: SteelspaceResponse = await response.json()
  if (responseJson.statusCode === 200) {
    const result: SteelspaceModelDownloadLinkResponse = <{ url: string }>responseJson.payload
    return result
  } else {
    return undefined
  }
}

export const getHistoryFileRequest = async (
  modelId: string,
  historyId: string,
  fileId: string
): Promise<SteelspaceFileResponse | undefined> => {
  const getDownloadLinkResponse = await getDownloadLinkToHistoryFileRequest(
    modelId,
    historyId,
    fileId
  )
  if (!getDownloadLinkResponse) return undefined
  const request = async () => {
    return await fetch(getDownloadLinkResponse.url, {
      method: 'GET',
      mode: 'cors',
    })
  }
  const response = await request()
  if (!response.ok) return undefined
  const buffer = await response.arrayBuffer()
  const result: SteelspaceFileResponse = {
    file: new Blob([buffer], { type: 'application/octet-stream' }),
  }
  return result
}
