import { Unsubscribe } from 'firebase/auth'
import Vue from 'vue'

const state = Vue.observable({
  bandwidthUsage: 0 as number,
  bandwidthLimitation: 0 as number,
  loading: false,
  unSubs: {} as Record<string, Unsubscribe>,
})

export const setUserServiceLoading = (value: boolean): void => {
  state.loading = value
}

export const setBandwidthLimitation = (value: number): void => {
  state.bandwidthLimitation = value
}

export const setBandwidthUsage = (value: number): void => {
  state.bandwidthUsage = value
}

export const setUserServiceUnSubs = (value: Record<string, Unsubscribe>): void => {
  state.unSubs = value
}

export const resetUserServiceState = (): void => {
  state.bandwidthUsage = 0
  state.bandwidthLimitation = 0
  state.loading = false
  state.unSubs = {}
}

export const userServiceStore = state as Readonly<typeof state>
