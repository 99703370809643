import { Unsubscribe } from 'firebase/auth'
import Vue from 'vue'
import { SteelspaceFolder } from './types/folder.model'

const state = Vue.observable({
  folders: [] as SteelspaceFolder[],
  loading: false,
  unSubs: {} as Record<string, Unsubscribe>,
})

export const setFolders = (value: SteelspaceFolder[]): void => {
  state.folders = value
}

export const setFolderLoading = (value: boolean): void => {
  state.loading = value
}

export const setFolderUnSubs = (value: Record<string, Unsubscribe>): void => {
  state.unSubs = value
}

export const resetFolderState = (): void => {
  state.folders = []
  state.loading = false
  state.unSubs = {}
}

export const folderStore = state as Readonly<typeof state>
