
import { modelStore } from '@/modules/model'
import { resetErrorState } from '@/services'
import Vue from 'vue'

export default Vue.extend({
  name: 'ErrorSnackbar',
  computed: {
    snackbarMessage(): string {
      return modelStore.error
    },
    showSnackBar: {
      get() {
        return Boolean(modelStore.error)
      },
      set() {
        resetErrorState()
      },
    },
  },
})
