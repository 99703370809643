import { authStore } from '@/modules/auth'
import { DtoBase, HTTPMethod } from '@/types'

const getIdToken = async (): Promise<string | undefined> => {
  const token = await authStore.currentSteelspaceUser?.getIdToken()
  return token
}

const createRequest = async (
  endpoint: string,
  method: HTTPMethod,
  baseUrl: string,
  payload?: DtoBase | FormData,
  apiVersion?: string,
  json = true
): Promise<() => Promise<Response>> => {
  const token = await getIdToken()
  return async () => {
    return await fetch(`${baseUrl}/${endpoint}`, {
      method,
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${token}`,
        ...(json ? { 'Content-Type': 'application/json' } : undefined),
        ...(apiVersion ? { 'api-version': apiVersion } : undefined),
      },
      body: json ? (payload as DtoBase)?.stringify() : (payload as FormData),
    })
  }
}

export const doRequest = async (
  endpoint: string,
  method: HTTPMethod,
  dto?: DtoBase | FormData,
  baseUrl?: string,
  apiVersion?: string,
  json = true
): Promise<Response> => {
  const baseUrl_ = baseUrl || process.env.VUE_APP_FB_API_BASEURL || ''

  const request = await createRequest(endpoint, method, baseUrl_, dto, apiVersion, json)
  const response = await request()
  return response
}

export const downloadFile = async (blob: Blob, filename: string): Promise<void> => {
  const a = document.createElement('a')
  a.href = window.URL.createObjectURL(blob)
  a.download = filename
  a.click()
}
