import { Model } from '@consteel/smadsteelloader'
import { guid } from '@consteel/stoke/src/ObjectDataBuilders/SmadsteelObjectBuilder'
import {
  Get,
  GetAll,
  HaunchedStructuralMember,
  LineLoad,
  LoadCase,
  LoadTransferSurface,
  NodalLoad,
  NodalSupport,
  PortionFolder,
  StructuralMember,
  StructuralPlate,
  SurfaceLoad,
} from '@consteel/storm'
import { ModelBase } from '@consteel/storm/src/Models/ModelBase'
import { stormStore } from './storm.store'

// get all
export const getStructuralMembers = (
  modelFile: Model | undefined | null,
  useSecondaryCache = false,
  query?: (obj: StructuralMember) => boolean
): StructuralMember[] =>
  getAll<StructuralMember>(modelFile, StructuralMember, query, useSecondaryCache)

export const getLoadCases = (
  modelFile: Model | undefined | null,
  useSecondaryCache = false,
  query?: (obj: LoadCase) => boolean
): LoadCase[] => getAll<LoadCase>(modelFile, LoadCase, query, useSecondaryCache)

export const getPortionFolders = (
  modelFile: Model | undefined | null,
  useSecondaryCache = false,
  query?: (obj: PortionFolder) => boolean
): PortionFolder[] => getAll<PortionFolder>(modelFile, PortionFolder, query, useSecondaryCache)

export const getLineLoads = (
  modelFile: Model | undefined | null,
  useSecondaryCache = false,
  query?: (obj: LineLoad) => boolean
): LineLoad[] => getAll<LineLoad>(modelFile, LineLoad, query, useSecondaryCache)

export const getSurfaceLoads = (
  modelFile: Model | undefined | null,
  useSecondaryCache = false,
  query?: (obj: SurfaceLoad) => boolean
): SurfaceLoad[] => getAll<SurfaceLoad>(modelFile, SurfaceLoad, query, useSecondaryCache)

export const getNodalLoads = (
  modelFile: Model | undefined | null,
  useSecondaryCache = false,
  query?: (obj: NodalLoad) => boolean
): NodalLoad[] => getAll<NodalLoad>(modelFile, NodalLoad, query, useSecondaryCache)

// get
export const getStructuralMember = (
  modelFile: Model | undefined | null,
  guid: guid,
  useSecondaryCache = false
): StructuralMember | null =>
  get<StructuralMember>(modelFile, guid, StructuralMember, useSecondaryCache)

export const getHaunchedMember = (
  modelFile: Model | undefined | null,
  guid: guid,
  useSecondaryCache = false
): HaunchedStructuralMember | null =>
  get<HaunchedStructuralMember>(modelFile, guid, HaunchedStructuralMember, useSecondaryCache)

export const getLineLoad = (
  modelFile: Model | undefined | null,
  guid: guid,
  useSecondaryCache = false
): LineLoad | null => get<LineLoad>(modelFile, guid, LineLoad, useSecondaryCache)

export const getLoadCase = (
  modelFile: Model | undefined | null,
  guid: guid,
  useSecondaryCache = false
): LoadCase | null => get<LoadCase>(modelFile, guid, LoadCase, useSecondaryCache)

export const getNodalLoad = (
  modelFile: Model | undefined | null,
  guid: guid,
  useSecondaryCache = false
): NodalLoad | null => get<NodalLoad>(modelFile, guid, NodalLoad, useSecondaryCache)

export const getLoadTransferSurface = (
  modelFile: Model | undefined | null,
  guid: guid,
  useSecondaryCache = false
): LoadTransferSurface | null =>
  get<LoadTransferSurface>(modelFile, guid, LoadTransferSurface, useSecondaryCache)

export const getStructuralPlate = (
  modelFile: Model | undefined | null,
  guid: guid,
  useSecondaryCache = false
): StructuralPlate | null =>
  get<StructuralPlate>(modelFile, guid, StructuralPlate, useSecondaryCache)

export const getNodalSupport = (
  modelFile: Model | undefined | null,
  guid: guid,
  useSecondaryCache = false
): NodalSupport | null => get<NodalSupport>(modelFile, guid, NodalSupport, useSecondaryCache)

export const getSurfaceLoad = (
  modelFile: Model | undefined | null,
  guid: guid,
  useSecondaryCache = false
): SurfaceLoad | null => get<SurfaceLoad>(modelFile, guid, SurfaceLoad, useSecondaryCache)

// helper functions
const getAll = <T extends ModelBase>(
  modelFile: Model | undefined | null,
  entityType: new () => T,
  query?: (obj: T) => boolean,
  useSecondaryCache = false
): T[] => {
  if (!modelFile) return []

  return GetAll<T>(
    modelFile,
    entityType,
    query,
    useSecondaryCache ? stormStore.secondaryCache : stormStore.cache
  )
}

const get = <T extends ModelBase>(
  modelFile: Model | undefined | null,
  guid: guid,
  entityType: new () => T,
  useSecondaryCache = false
): T | null => {
  if (!modelFile) return null

  return Get<T>(
    modelFile,
    guid,
    entityType,
    useSecondaryCache ? stormStore.secondaryCache : stormStore.cache
  )
}
