import { Unsubscribe } from 'firebase/auth'
import Vue from 'vue'
import { SteelspaceModel } from './types/ModelItem.model'

const state = Vue.observable({
  models: [] as SteelspaceModel[],
  model: null as SteelspaceModel | null,
  publicModels: null as SteelspaceModel[] | null,
  loading: false,
  unSubs: {} as Record<string, Unsubscribe>,
  error: '',
})

export const setModels = (value: SteelspaceModel[]): void => {
  state.models = value
}

export const setModel = (value: SteelspaceModel | null): void => {
  state.model = value
}

export const setPublicModels = (value: SteelspaceModel[]): void => {
  state.publicModels = value
}

export function resetModel(): void {
  state.model = null
}

export const setModelLoading = (value: boolean): void => {
  state.loading = value
}

export const setModelUnSubs = (value: Record<string, Unsubscribe>): void => {
  state.unSubs = value
}

export const setModelError = (errorMsg: string): void => {
  state.error = errorMsg
}

export const resetModelState = (): void => {
  state.models = []
  state.model = null
  state.publicModels = null
  state.loading = false
  state.unSubs = {}
  state.error = ''
}

export const modelStore = state as Readonly<typeof state>
