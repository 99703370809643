import { Unsubscribe } from 'firebase/auth'
import Vue from 'vue'
import { HistoryItem, HistoryItemFile } from './types/HistoryItem.model'

const state = Vue.observable({
  loading: false,
  historyItems: [] as HistoryItem[],
  historyItem: null as HistoryItem | null,
  unSubs: {} as Record<string, Unsubscribe>,
  historyErrorMessage: '',
  historyErrorTitle: '',
  historyErrorIcon: 'mdi-alert',
  historyErrorIconColor: 'warning',
})

export const setHistoryItemFiles = (value: HistoryItemFile[]): void => {
  if (!state.historyItem) return
  state.historyItem.historyItemFiles = value
}

export const setHistoryItems = (value: HistoryItem[]): void => {
  state.historyItems = value
}

export const setHistoryItem = (value: HistoryItem | null): void => {
  state.historyItem = value
}

export const setHistoryUnSubs = (value: Record<string, Unsubscribe>): void => {
  state.unSubs = value
}

export const setHistoryLoading = (value: boolean): void => {
  state.loading = value
}

export const resetHistoryError = (): void => {
  state.historyErrorMessage = ''
  state.historyErrorTitle = ''
  state.historyErrorIcon = 'mdi-alert'
  state.historyErrorIconColor = 'warning'
}

export const setHistoryErrorMessage = (value: string): void => {
  state.historyErrorMessage = value
}

export const setHistoryErrorTitle = (value: string): void => {
  state.historyErrorTitle = value
}

export const setHistoryErrorIcon = (value: string): void => {
  state.historyErrorIcon = value
}

export const setHistoryErrorIconColor = (value: string): void => {
  state.historyErrorIconColor = value
}

export const resetHistoryState = (): void => {
  state.loading = false
  state.historyItems = []
  state.historyItem = null
  state.unSubs = {}
  resetHistoryError()
}

export const historyStore = state as Readonly<typeof state>
