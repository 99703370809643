
import Vue from 'vue'
import ErrorSnackbar from './components/Snackbar/ErrorSnackbar.vue'
import { unSubFromFirebaseSnapshots } from './services/firebase-sdk.service'

export default Vue.extend({
  name: 'App',
  components: {
    ErrorSnackbar,
  },
  beforeDestroy() {
    unSubFromFirebaseSnapshots()
  },
})
