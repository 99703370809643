import { resetFolderState, unSubFromFolderFirebaseSnapshots } from '../modules/folder'
import { resetHistoryState, unSubFromHistoryFirebaseSnapshots } from '../modules/history'
import { resetModelState, setModelError, unSubFromModelFirebaseSnapshots } from '../modules/model'
import { resetUserServiceState, unSubFromBandwidthFirebaseSnapshots } from '../modules/userService'

export const unSubFromFirebaseSnapshots = (): void => {
  unSubFromBandwidthFirebaseSnapshots()
  unSubFromFolderFirebaseSnapshots()
  unSubFromHistoryFirebaseSnapshots()
  unSubFromModelFirebaseSnapshots()
}

export const resetFirebaseState = (): void => {
  resetUserServiceState()
  resetModelState()
  resetHistoryState()
  resetFolderState()
}

export const resetErrorState = (): void => {
  setModelError('')
}
