import {
  CommentSettings,
  commentStore,
  getCommentsBasedOnModelCompareState,
} from '@/modules/comment'
import { Model } from '@consteel/smadsteelloader'
import {
  Get,
  GetAll,
  LineLoad,
  LoadCase,
  NodalLoad,
  StructuralMember,
  SurfaceLoad,
} from '@consteel/storm'
import { guid } from '@consteel/storm/src/Models/ModelBase'
import {
  Color3,
  CompareView,
  GuiObject,
  RenderContext,
  StructuralView,
  StructuralViewModel,
  Vector3,
} from '@consteel/straw'
import { ViewBase } from '@consteel/straw/src/Views/ViewBase'
import Vue, { nextTick } from 'vue'
import { AvailableLabelComponents } from '../components/Viewer/Labels'
import { FloatingComment, FloatingCommentInput, FloatingLabel } from '../types'

type asyncCallbackFunction<T, P> = (object: P) => Promise<T>

export enum cameraMode {
  orthographic,
  perspective,
}

export enum ModelStateNumber {
  primary,
  secondary,
}

export enum IsolationType {
  hideEdges,
  ghost,
}

export enum CommentSelectType {
  GLOBAL,
  OBJECT_SPECIFIC,
  OBJECT_GROUP_SPECIFIC,
}

type ModelObjectToRender = {
  rawSmadsteelModel?: Model | null
  viewModel?: StructuralViewModel | null
  modelNumber?: string | null
}

const state = Vue.observable({
  model: { rawSmadsteelModel: null, viewModel: null, modelNumber: null } as ModelObjectToRender,
  secondaryModel: {
    rawSmadsteelModel: null,
    viewModel: null,
    modelNumber: null,
  } as ModelObjectToRender,
  activeMainTab: 0,
  activeSideTab: 0,
  viewControlVisibility: false,
  clipPlaneVisibility: false,
  labelVisibility: true,
  supportVisibility: true,
  commentVisibility: true,
  loadVisibility: true,
  labelId: 0,
  cantLoad: false,
  errorMessage: '',
  renderContext: undefined as RenderContext | undefined,
  currentView: undefined as ViewBase | undefined,
  currentViewLoading: false,
  compareFadingLevel: 0 as number,
  labelCallback: undefined as asyncCallbackFunction<void, unknown> | undefined,
  pickCallback: undefined as asyncCallbackFunction<void, unknown> | undefined,
  selectedIds: [] as string[],
  isolatedIds: [] as string[],
  isolatedPortiosIds: [] as string[],
  isolationEnabled: false,
  isolationType: IsolationType.ghost as IsolationType,
  selectedCommentIds: [] as string[],
  scaleOfLables: 1,
  scaleOfComments: 1,
  scaleOfLoads: 50,
  scaleOfSupports: 25,
  clipPlaneX: 0,
  clipPlaneY: 0,
  clipPlaneZ: 0,
  activeLoadId: '',
  xRayToggled: false,
  addedItems: null as null | guid[],
  deletedItems: null as null | guid[],
  changedItems: null as null | guid[],
  showedCompareStates: ['added', 'changed', 'deleted'] as string[],
  currentCameraAlpha: null as null | number,
  currentCameraBeta: null as null | number,
  currentCameraRadius: null as null | number,
  currentCameraTarget: null as null | Vector3,
  currentCameraPosition: null as null | Vector3,
  floatingLabels: [] as FloatingLabel[],
  cameraMode: cameraMode.perspective,
  commentSelectType: CommentSelectType.GLOBAL as CommentSelectType,
  floatingComments: [] as FloatingComment[],
  floatingCommentsSecondary: [] as FloatingComment[],
  floatingCommentInputs: [] as FloatingCommentInput[],
  labelShowAddLabelOption: true,
  commentShowCreateCommentOption: true,
  commentFocusOnObjectOption: true,
  unselectedMembersToTransparentOption: true,
  unselectedMembersToTransparentOptionDisabled: false,
  moveCameraToSelectionOption: true,
  filterUnreadComments: false,
})

export function setIsolationType(value: IsolationType): void {
  state.isolationType = value
  setIsolation(state.isolatedIds)
}

export function setIsolationEnabled(value: boolean, onlyInStore = false): void {
  state.isolationEnabled = value
  if (onlyInStore) return
  setHiddenParamOnGuiObjects()
  const hideEdges = state.isolationType == IsolationType.hideEdges

  if (value) {
    state.currentView?.SetSelectionByRay(true)
    state.currentView?.SetIsolation(state.isolatedIds, true, hideEdges)
  } else {
    state.currentView?.SetSelectionByRay(false)
    state.currentView?.SetIsolation([], true, hideEdges)
  }
}

export function setIsolation(ids: string[]): void {
  state.isolatedIds = ids
  setHiddenParamOnGuiObjects()
  state.currentView?.SetIsolation(
    state.isolatedIds,
    true,
    state.isolationType == IsolationType.hideEdges
  )
}

export function getCommentSettings(): CommentSettings {
  return {
    xRayToggle: state.xRayToggled,
    loadScale: { active: state.loadVisibility, scale: state.scaleOfLoads },
    supportScale: { active: state.supportVisibility, scale: state.scaleOfSupports },
    labelScale: { active: state.labelVisibility, scale: state.scaleOfLables },
    commentScale: { active: state.commentVisibility, scale: state.scaleOfComments },
    clipPlane: {
      active: state.clipPlaneVisibility,
      position: {
        x: state.clipPlaneX,
        y: state.clipPlaneY,
        z: state.clipPlaneZ,
      },
    },
    camera: {
      alpha: state.currentCameraAlpha || 0,
      beta: state.currentCameraBeta || 0,
      radius: state.currentCameraRadius || 0,
      position: state.currentCameraPosition
        ? {
            x: state.currentCameraPosition.x,
            y: state.currentCameraPosition.y,
            z: state.currentCameraPosition.z,
          }
        : null,
      target: state.currentCameraTarget
        ? {
            x: state.currentCameraTarget.x,
            y: state.currentCameraTarget.y,
            z: state.currentCameraTarget.z,
          }
        : null,
    },
  }
}

export function setModelViewerErrorMessage(value: string): void {
  state.errorMessage = value
}

export function setViewControlVisibility(value: boolean): void {
  state.viewControlVisibility = value
}

export function setCantLoad(value: boolean): void {
  state.cantLoad = value
}

export function setActiveMainTab(value: number): void {
  state.activeMainTab = value
}

export function setActiveSideTab(value: number): void {
  state.activeSideTab = value
}

export function setCompareFadingLevel(value: number): void {
  state.compareFadingLevel = value
}

export function setPrimaryModelNumber(modelNumber: string | null): void {
  state.model.modelNumber = modelNumber
}

export function setSecondaryModelNumber(modelNumber: string | null): void {
  state.secondaryModel.modelNumber = modelNumber
}

export const modelViewerStore = state as Readonly<typeof state>

export function getShowedCompareStates(): string[] {
  return state.showedCompareStates
}

export function setShowedCompareStates(compareStates: string[]): void {
  state.showedCompareStates = compareStates
}

export function clearSecondaryModel(): void {
  state.secondaryModel.modelNumber = null
  state.secondaryModel.viewModel = null
  state.secondaryModel.rawSmadsteelModel = null
}

export function setSecondaryModel(
  rawSmadsteelModel?: Model,
  viewModel?: StructuralViewModel,
  modelNumber?: string
): void {
  state.secondaryModel.rawSmadsteelModel = rawSmadsteelModel
  state.secondaryModel.viewModel = viewModel
  state.secondaryModel.modelNumber = modelNumber
}

export function clearModel(): void {
  state.model.modelNumber = null
  state.model.viewModel = null
  state.model.rawSmadsteelModel = null
}

export function setHiddenParamOnGuiObjects(): void {
  const floatingComments = state.floatingComments
  const floatingCommentsSecondary = state.floatingCommentsSecondary

  const _floatingCommentInputs = state.floatingCommentInputs
  _floatingCommentInputs.forEach((object) => {
    object.hidden = !state.currentView?.isPointOnView(object.position)

    const objectIsHiddenByClipPlane = !state.currentView?.isPointOnView(object.position)
    const objectIsHiddenByIsolation =
      state.isolatedIds.length && state.isolationEnabled && !state.isolatedIds.includes(object.id)
    object.hidden = objectIsHiddenByIsolation || objectIsHiddenByClipPlane
  })
  state.floatingCommentInputs = [..._floatingCommentInputs] //TODO, don't handle as array

  const _floatingLabels = state.floatingLabels
  _floatingLabels.forEach((object) => {
    const objectIsHiddenByClipPlane = !state.currentView?.isPointOnView(object.position)
    const objectIsHiddenByIsolation =
      state.isolatedIds.length &&
      state.isolationEnabled &&
      !state.isolatedIds.includes(object.objectGuid)
    object.hidden = objectIsHiddenByIsolation || objectIsHiddenByClipPlane
  })
  state.floatingLabels = [..._floatingLabels]

  const _floatingComments = floatingComments
  _floatingComments.forEach((object) => {
    object.hidden = !state.currentView?.isPointOnView(object.position)

    const objectIsHiddenByClipPlane = !state.currentView?.isPointOnView(object.position)
    const objectIsHiddenByIsolation =
      state.isolatedIds.length &&
      state.isolationEnabled &&
      !state.isolatedIds.find((isolatedId) => object.smadsteelIds.includes(isolatedId))

    object.hidden = objectIsHiddenByIsolation || objectIsHiddenByClipPlane
  })

  const _floatingCommentsSecondary = floatingCommentsSecondary
  _floatingCommentsSecondary.forEach((object) => {
    object.hidden = !state.currentView?.isPointOnView(object.position)
  })

  state.floatingComments = _floatingComments
  state.floatingCommentsSecondary = _floatingCommentsSecondary
}

export function setModel(
  rawSmadsteelModel: Model,
  viewModel: StructuralViewModel,
  modelNumber?: string
): void {
  state.model.rawSmadsteelModel = rawSmadsteelModel
  state.model.viewModel = viewModel

  if (modelNumber) state.model.modelNumber = modelNumber
}

export function setRenderContext(renderContext: RenderContext): void {
  state.renderContext?.Dispose()
  state.renderContext = renderContext
}

export function setGuiObjects(): void {
  const guiObjects = [] as GuiObject[]

  const floatingComments = [...state.floatingComments, ...state.floatingCommentsSecondary]

  setHiddenParamOnGuiObjects()

  floatingComments.forEach((floatingComment) => {
    const el = document.getElementById(floatingComment.id)

    if (el) guiObjects.push(new GuiObject(floatingComment.position, el))
  })

  state.floatingCommentInputs.forEach((floatingCommentInput) => {
    const el = document.getElementById(floatingCommentInput.id)
    if (el) guiObjects.push(new GuiObject(floatingCommentInput.position, el))
  })

  state.floatingLabels.forEach((floatingComment) => {
    const el = document.getElementById(floatingComment.id)
    if (el) guiObjects.push(new GuiObject(floatingComment.position, el))
  })

  state.currentView?.SetGuiObjects(...guiObjects)
}

export async function removeFloatingCommentInputs(): Promise<void> {
  state.floatingCommentInputs = []
  await nextTick()
  setGuiObjects()
}

export async function addFloatingCommentInput(commentInput: FloatingCommentInput): Promise<void> {
  state.floatingCommentInputs = [commentInput] //TODO, don't handle as array
  await nextTick()
  setGuiObjects()
}

export async function removeFloatingCommentInput(commentInputId: string): Promise<void> {
  state.floatingCommentInputs = state.floatingCommentInputs.filter(
    (item) => item.id !== commentInputId
  )
  await nextTick()
  setGuiObjects()
}

export function setLabelId(value: number): void {
  state.labelId = value
}

export async function addFloatingLabels(
  objectGuid: string,
  objectTypeName: string,
  position: Vector3
): Promise<void> {
  const labelHtmlId = `label-${modelViewerStore.labelId}`
  setLabelId(modelViewerStore.labelId + 1)

  const name = AvailableLabelComponents[`${objectTypeName}Label`]
    ? `${objectTypeName}Label`
    : 'DefaultLabel'

  state.floatingLabels.push({
    name: name,
    id: labelHtmlId,
    objectGuid,
    objectTypeName,
    close: () => {
      removeLabel(labelHtmlId)
    },
    position,
  })

  await nextTick()
  setGuiObjects()
}

export function removeLabel(labelId: string): void {
  state.floatingLabels = state.floatingLabels.filter((item) => item.id !== labelId)
}

export function setCurrentCamera(): void {
  if (state?.renderContext) {
    const { alpha, beta, radius, target, position } = state.renderContext.GetCameraPosition()

    state.currentCameraAlpha = alpha
    state.currentCameraBeta = beta
    state.currentCameraRadius = radius
    state.currentCameraTarget = target
    state.currentCameraPosition = position
  }
}

export async function drawModelView(initialLoad = false): Promise<void> {
  if (!state.model.viewModel) {
    console.error('View model not set')
    return
  }

  if (!state.renderContext) {
    console.error('Render context not set')
    return
  }

  clearView()

  const view = new StructuralView(state.model.viewModel, state.renderContext)
  await drawView(view, initialLoad)
}

export async function drawCompareView(initialLoad = true): Promise<void> {
  if (!state.model.viewModel) {
    console.error('View model not set')
    return
  }

  if (!state.secondaryModel.viewModel) {
    console.error('Secondary view model not set')
    return
  }

  if (!state.renderContext) {
    console.error('Render context not set')
    return
  }

  clearView()

  const view = new CompareView(
    state.model.viewModel,
    state.secondaryModel.viewModel,
    state.renderContext,
    {
      added: new Set(state.showedCompareStates.includes('added') ? state.addedItems : []),
      changed: new Set(state.showedCompareStates.includes('changed') ? state.changedItems : []),
      deleted: new Set(state.showedCompareStates.includes('deleted') ? state.deletedItems : []),
    }
  )

  await drawView(view, initialLoad)
}

export function clearView(): void {
  state.currentView?.Dispose()
  state.currentView = undefined
}

async function drawView(view: ViewBase, initialLoad: boolean): Promise<void> {
  if (!state.renderContext) {
    console.error('Render context not set')
    return
  }

  if (!initialLoad) {
    setCurrentViewLoading(true)
    await new Promise((resolve) => setTimeout(resolve, 1000))
  }

  state.currentView = view
  state.cameraMode = cameraMode.perspective

  state.currentView.Draw()

  toggleXray(state.xRayToggled)
  scaleLabels(state.scaleOfLables)
  scaleLoads(state.scaleOfLoads)
  scaleSupports(state.scaleOfSupports)
  scaleComments(state.scaleOfComments)

  state.currentView.RenderEdges()
  state.currentView.SetSelection(...state.selectedIds)

  state.renderContext.ResetCamera()

  if (state.labelCallback) {
    state.currentView.OnLabel(state.labelCallback)
  }
  if (state.pickCallback) {
    state.currentView.OnPick(state.pickCallback)
  }

  colorMembersWithUnknownSection()

  setCurrentViewLoading(false)
}

export function colorMembersWithUnknownSection(): void {
  if (!state.currentView) return

  const unknownMembers = GetAll<StructuralMember>(
    state.model.rawSmadsteelModel!,
    StructuralMember,
    (member: StructuralMember) => (member?.section?.name?.includes('Placeholder') ? true : false)
  )

  state.currentView.SetInstanceColor(
    ...unknownMembers.map((member): { id: string; color: Color3 } => {
      return { id: member.id!, color: new Color3(1, 0, 0) }
    })
  )
}

export function deleteLabels(): void {
  state.floatingLabels = []
}

export function setCurrentViewLoading(value: boolean): void {
  state.currentViewLoading = value
}

export function setLabelCallback(callback: (object) => Promise<void>): void {
  state.labelCallback = callback

  state.currentView?.OnLabel(state.labelCallback)
}

export function setPickCallback(callback: (pickinfo) => Promise<void>): void {
  state.pickCallback = callback

  state.currentView?.OnPick(state.pickCallback)
}

export function addItemsToSelection(...ids: string[]): void {
  const filteredIds = [...ids].filter((id) => !state.selectedIds.includes(id))

  if (!filteredIds.length) return

  const vectors = state.currentView?.AddSelection(...filteredIds)
  if (state.moveCameraToSelectionOption && vectors && vectors.length > 0) {
    const boundingBox = calculateBoundingBoxFromPoints(vectors)
    moveCameraToBoundingBox(boundingBox)
  }

  state.selectedIds.push(...filteredIds)
}

export const setSelectionAndIsolation = (...ids: string[]): void => {
  addItemsToSelection(...ids)
  if (
    state.unselectedMembersToTransparentOption &&
    !state.unselectedMembersToTransparentOptionDisabled
  )
    setIsolation(modelViewerStore.selectedIds)
}

export function setIsolationPortionIds(value: string[]): void {
  state.isolatedPortiosIds = value
}

export function setSelectedCommentIds(ids: string[]): void {
  state.selectedCommentIds = ids
}

export function removeItemsFromSelection(...ids: string[]): void {
  state.selectedIds = state.selectedIds.filter((selectedId) => !ids.includes(selectedId))
  const vectors = state.currentView?.RemoveSelection(...ids)
  if (state.moveCameraToSelectionOption && vectors && vectors.length > 0) {
    const boundingBox = calculateBoundingBoxFromPoints(vectors)
    moveCameraToBoundingBox(boundingBox)
  }
}

export const removeSelectionAndIsolation = (...ids: string[]): void => {
  removeItemsFromSelection(...ids)
  if (
    state.unselectedMembersToTransparentOption &&
    !state.unselectedMembersToTransparentOptionDisabled
  )
    setIsolation(modelViewerStore.selectedIds)
}

export function removeSelection(): void {
  removeItemsFromSelection(...state.selectedIds)
}

export function scaleLabels(scale: number): void {
  state.scaleOfLables = scale
}

export function scaleComments(scale: number): void {
  state.scaleOfComments = scale
}

export function scaleLoads(scale: number): void {
  state.scaleOfLoads = scale
  state.currentView?.ScaleLoads(state.scaleOfLoads)
}

export function scaleSupports(scale: number): void {
  state.scaleOfSupports = scale
  state.currentView?.ScaleSupports(state.scaleOfSupports)
}

export function setClipPlaneX(x: number): void {
  state.clipPlaneX = x
}

export function setClipPlaneY(y: number): void {
  state.clipPlaneY = y
}

export function setClipPlaneZ(z: number): void {
  state.clipPlaneZ = z
}

export function setChangedItems(guids: guid[] | null): void {
  state.changedItems = guids
}

export function setDeletedItems(guids: guid[] | null): void {
  state.deletedItems = guids
}

export function setAddedItems(guids: guid[] | null): void {
  state.addedItems = guids
}

export function getLoadCaseById(loadCaseId: string): LoadCase | null {
  let loadCase: LoadCase | null = null
  if (state.model.rawSmadsteelModel) {
    loadCase = Get<LoadCase>(state.model.rawSmadsteelModel, loadCaseId, LoadCase)
  }

  if (state.secondaryModel.rawSmadsteelModel) {
    loadCase = Get<LoadCase>(state.secondaryModel.rawSmadsteelModel, loadCaseId, LoadCase)
  }

  return loadCase
}

export function getLoadsByLoadCase(model: Model, loadCaseId: string): string[] {
  const lineLoads = GetAll<LineLoad>(
    model,
    LineLoad,
    (obj: LineLoad) => obj.loadCase?.id === loadCaseId
  )
  const surfaceLoads = GetAll<SurfaceLoad>(
    model,
    SurfaceLoad,
    (obj: SurfaceLoad) => obj.loadCase?.id === loadCaseId
  )
  const nodalLoads = GetAll<NodalLoad>(
    model,
    NodalLoad,
    (obj: NodalLoad) => obj.loadCase?.id === loadCaseId
  )

  const finalLoads = (
    (lineLoads as Array<LineLoad | SurfaceLoad>).concat(surfaceLoads) as Array<
      LineLoad | SurfaceLoad | NodalLoad
    >
  )
    .concat(nodalLoads)
    .map((load) => load.id)

  return finalLoads.flatMap((load) => (load ? [load] : []))
}

export function setActiveLoad(id: string): void {
  state.activeLoadId = id
  deleteLabels()

  const model = state.model.rawSmadsteelModel

  const secondaryModel = state.secondaryModel.rawSmadsteelModel

  if (!model) return

  const finalLoads = getLoadsByLoadCase(model, id)

  if (!finalLoads.length) return

  state.currentView?.SetVisibleLoads(...finalLoads)

  if (secondaryModel) {
    const finalLoadsSecondary = getLoadsByLoadCase(secondaryModel, id)
    state.currentView?.SetSecondaryVisibleLoads(...finalLoadsSecondary)
  }
}

export function removeActiveLoad(): void {
  state.activeLoadId = ''
  if (state.currentView) state.currentView.ClearVisibleLoads()
}

export function toggleXray(toggle: boolean): void {
  if (state.currentView instanceof StructuralView) {
    state.currentView.toggleXray(toggle)
  }
  state.xRayToggled = toggle
}

export function toggleLoads(value: boolean): void {
  state.loadVisibility = value
}

export function toggleSupports(value: boolean): void {
  state.supportVisibility = value
}

export function toggleClipPlane(value: boolean): void {
  state.clipPlaneVisibility = value
}

export function toggleLabels(value: boolean): void {
  state.labelVisibility = value
}

export function toggleComments(value: boolean): void {
  state.commentVisibility = value
}

export function setCameraMode(mode: cameraMode): void {
  state.cameraMode = mode
  switch (mode) {
    case cameraMode.orthographic:
      state.renderContext?.SetOrthographicProjection()
      break
    case cameraMode.perspective:
      state.renderContext?.SetPerspectiveProjection()
      break
    default:
      break
  }
}

export function resetCamera(): void {
  setCameraMode(cameraMode.perspective)
  state.renderContext?.ResetCamera()
}

export function moveCameraToBoundingBox(boundingBox: { min: Vector3; max: Vector3 }): void {
  state.renderContext?.MoveCameraToBoundingBox(boundingBox)
}

export function isometricCamera(): void {
  setCameraMode(cameraMode.orthographic)
  state.renderContext?.SetIsometricProjection()
}

export async function createScreenshotAsync(
  cameraType: 'main' | 'preview' = 'main'
): Promise<string | undefined> {
  return await state.renderContext?.CreateScreenshotAsync(cameraType)
}

export function setUnselectedMembersToTransparentOption(value: boolean): void {
  state.unselectedMembersToTransparentOption = value
}
export function setUnselectedMembersToTransparentOptionDisabled(value: boolean): void {
  state.unselectedMembersToTransparentOptionDisabled = value
}
export function setMoveCameraToSelectionOption(value: boolean): void {
  state.moveCameraToSelectionOption = value
}

export function setCommentSelectTypeBySelectedIds(): void {
  switch (true) {
    case state.selectedIds.length > 1:
      state.commentSelectType = CommentSelectType.OBJECT_GROUP_SPECIFIC
      break
    case state.selectedIds.length === 1:
      state.commentSelectType = CommentSelectType.OBJECT_SPECIFIC
      break
    default:
      state.commentSelectType = CommentSelectType.GLOBAL
      break
  }
}

export function setCommentSelectType(type: CommentSelectType): void {
  state.commentSelectType = type
}

export function setFilterUnreadComments(value: boolean): void {
  state.filterUnreadComments = value
}

export async function setFloatingComments(compareState = ModelStateNumber.primary): Promise<void> {
  const floatingComments = [] as FloatingComment[]
  const comments = getCommentsBasedOnModelCompareState(compareState)

  if (!comments) {
    return
  }

  if (state.commentSelectType !== CommentSelectType.GLOBAL) {
    comments.forEach((comment) => {
      //if selected comment type is SINGLE OBJECT and comment has not exactly 1 element, then return
      if (
        state.commentSelectType === CommentSelectType.OBJECT_SPECIFIC &&
        comment.smadsteelIds.length !== 1
      )
        return

      //if selected comment type is GROUP and comment has less than 2 element, then return
      if (
        state.commentSelectType === CommentSelectType.OBJECT_GROUP_SPECIFIC &&
        comment.smadsteelIds.length <= 1
      )
        return

      const commentWithSameObjectGroup = floatingComments.find((floatingComment) => {
        //have the same smadSteelIds
        //https://stackoverflow.com/questions/6229197/how-to-know-if-two-arrays-have-the-same-values

        return (
          comment.smadsteelIds.sort().join(',') === floatingComment.smadsteelIds.sort().join(',')
        )
      })
      if (commentWithSameObjectGroup) {
        commentWithSameObjectGroup.commentIds = [
          ...commentWithSameObjectGroup.commentIds,
          comment.id,
        ]
      } else {
        floatingComments.push({
          id: comment.id,
          commentIds: [comment.id],
          position: comment.position
            ? new Vector3(comment.position.x, comment.position.y, comment.position.z)
            : Vector3.Zero(),
          smadsteelIds: comment.smadsteelIds,
        })
      }
    })
  }

  compareState === ModelStateNumber.primary
    ? (state.floatingComments = floatingComments)
    : (state.floatingCommentsSecondary = floatingComments)
  await nextTick()
  setGuiObjects()
}

export function getCompareStateBasedOnFadingLevel(): ModelStateNumber {
  return state.compareFadingLevel > 50 ? ModelStateNumber.secondary : ModelStateNumber.primary
}

export function moveCameraToComment(commentId: string): void {
  if (!state.commentFocusOnObjectOption) return

  setCurrentCamera()

  const comment = commentStore.comments.find((comment) => comment.id === commentId)
  if (!comment) {
    console.error('Selected comment not found')
    return
  }

  if (comment.commentSettings.camera.position && comment.commentSettings.camera.target) {
    modelViewerStore.renderContext?.SetCameraPosition({
      alpha: comment.commentSettings.camera.alpha,
      beta: comment.commentSettings.camera.beta,
      radius: comment.commentSettings.camera.radius,
      position: new Vector3(
        comment.commentSettings.camera.position.x,
        comment.commentSettings.camera.position.y,
        comment.commentSettings.camera.position.z
      ),
      target: new Vector3(
        comment.commentSettings.camera.target.x,
        comment.commentSettings.camera.target.y,
        comment.commentSettings.camera.target.z
      ),
      animation: true,
      speedRatio: 1,
    })
  }
}

export function setLabelShowAddLabelOption(value: boolean): void {
  state.labelShowAddLabelOption = value
}

export function setCommentShowCreateCommentOption(value: boolean): void {
  state.commentShowCreateCommentOption = value
}

export function setCommentFocusOnObjectOption(value: boolean): void {
  state.commentFocusOnObjectOption = value
}

export function setDipslaySettings(commentId: string): void {
  const comment = commentStore.comments.find((comment) => comment.id === commentId)
  if (!comment) {
    console.error('Selected comment not found')
    return
  }

  // Clip plane settings

  toggleClipPlane(comment.commentSettings.clipPlane.active)

  const { x, y, z } = comment.commentSettings.clipPlane.position
  setClipPlaneX(x)
  setClipPlaneY(y)
  setClipPlaneZ(z)

  if (comment.commentSettings.clipPlane.active) {
    modelViewerStore.currentView?.SetClipPlaneX(x)
    modelViewerStore.currentView?.SetClipPlaneY(y)
    modelViewerStore.currentView?.SetClipPlaneZ(z)
  } else {
    modelViewerStore.currentView?.ClearClipPlaneX()
    modelViewerStore.currentView?.ClearClipPlaneY()
    modelViewerStore.currentView?.ClearClipPlaneZ()
  }

  // X-ray settings

  toggleXray(comment.commentSettings.xRayToggle)
}

// utils
export function calculateBoundingBoxFromPoints(points: Vector3[]): { min: Vector3; max: Vector3 } {
  let min = points[0]
  let max = points[0]
  if (points.length === 1) {
    const modifier = new Vector3(0.2, 0.2, 0.2) // TODO
    min = Vector3.Minimize(min, min.subtract(modifier))
    max = Vector3.Maximize(max, max.add(modifier))
  } else {
    points.forEach((matrix) => {
      min = Vector3.Minimize(min, matrix)
      max = Vector3.Maximize(max, matrix)
    })
  }
  return { min: min, max: max } as { min: Vector3; max: Vector3 }
}
