import { getModelPreviewUrlFromCache, saveModelPreviewUrlToCache } from '@/services/cache.service'
import { formatSize } from '@/services/filesizeformatter.service'
import { getModelPreviewUrl } from '../model.service'

export type ModelStatusType = 'valid' | 'maybe_invalid' | 'invalid'
type FileExtensionType = 'csm' | 'csr' | 'smadsteel' | 'saf'
type OriginType = 'consteel' | 'pangolin' | 'website'

export interface ModelUserData {
  id: string
  email: string
  name: string
}

export interface SteelspaceModelRelatedFile {
  checksum: string
  creationDate: number
  extension: string
  id: string
  lastOpenDate: number
  name: string
  size: number
  status: string
  storagePath: string
}

export enum SharePlatform {
  Steelspace,
  SteelspaceAndDesktop,
}

export enum ShareType {
  Model,
  ModelAndResult,
}

export class SteelspaceModelUserShare {
  user = {} as ModelUserData
  canViewModel = false
  canViewResult = false
  canDownloadModel = false
  canDownloadResult = false
  canComment = false

  constructor(obj?: Record<string, unknown>) {
    if (obj) {
      Object.assign(this, obj)
    }
  }

  GetSharePlatform(): SharePlatform {
    if (this.canDownloadModel) return SharePlatform.SteelspaceAndDesktop
    else return SharePlatform.Steelspace
  }

  GetShareType(): ShareType {
    if (this.canViewResult) return ShareType.ModelAndResult
    else return ShareType.Model
  }

  SetShare(platform: SharePlatform, type: ShareType): void {
    if (platform === SharePlatform.SteelspaceAndDesktop) {
      if (type === ShareType.ModelAndResult) {
        this.canComment = true
        this.canViewModel = true
        this.canViewResult = true
        this.canDownloadModel = true
        this.canDownloadResult = true
      } else if (type === ShareType.Model) {
        this.canComment = true
        this.canViewModel = true
        this.canViewResult = false
        this.canDownloadModel = true
        this.canDownloadResult = false
      }
    } else if (platform === SharePlatform.Steelspace) {
      if (type === ShareType.ModelAndResult) {
        this.canComment = true
        this.canViewModel = true
        this.canViewResult = true
        this.canDownloadModel = false
        this.canDownloadResult = false
      } else if (type === ShareType.Model) {
        this.canComment = true
        this.canViewModel = true
        this.canViewResult = false
        this.canDownloadModel = false
        this.canDownloadResult = false
      }
    }
  }
}

export class SteelspaceModel {
  id = ''
  status = '' as ModelStatusType
  creationDate = 0
  lastOpenDate = 0
  lastModificationDate = 0
  name = ''
  description = ''
  tags = [] as string[]
  checksum = ''
  extension = '' as FileExtensionType
  storagePath = ''
  uploadOrigin = '' as OriginType
  previewStoragePath = ''
  previewUrl = ''
  size = 0
  parentId = ''
  parentName = ''
  userData = {} as ModelUserData
  isOrigin = false
  originModelId = ''
  originUserData = {} as ModelUserData
  isPublic = false
  canViewModel = [] as ModelUserData[]
  canViewResult = [] as ModelUserData[]
  canDownloadModel = [] as ModelUserData[]
  canDownloadResult = [] as ModelUserData[]
  canComment = [] as ModelUserData[]
  relatedFiles = [] as SteelspaceModelRelatedFile[]
  number = ''

  constructor(obj?: Record<string, unknown> | SteelspaceModel) {
    if (obj) {
      Object.assign(this, obj)

      void this.setPreviewUrl()
    }
  }

  static CreateFrom(model: SteelspaceModel): SteelspaceModel {
    const newModel = new SteelspaceModel()

    Object.assign(newModel, model)
    newModel.id = ''
    newModel.isPublic = false
    newModel.canViewModel = []
    newModel.canViewResult = []
    newModel.canDownloadModel = []
    newModel.canDownloadResult = []
    newModel.canComment = []

    return newModel
  }

  private async setPreviewUrl() {
    const previewUrl = getModelPreviewUrlFromCache(this)

    if (previewUrl) {
      this.previewUrl = previewUrl
      return
    }

    const res = await getModelPreviewUrl(this.id)

    if (res) {
      this.previewUrl = res
      saveModelPreviewUrlToCache(this)
    }
  }

  getFormattedFileSize(): string {
    const relatedFilesSize = this.relatedFiles.reduce((sum, curr) => sum + curr.size, 0)
    return formatSize(this.size + relatedFilesSize)
  }

  getShares(): SteelspaceModelUserShare[] {
    const shares = [] as SteelspaceModelUserShare[]
    let type: ShareType
    let platform: SharePlatform
    this.canViewModel.forEach((user) => {
      const canViewResult = this.canViewResult.find((u) => u.email === user.email)
      const canDownloadModel = this.canDownloadModel.find((u) => u.email === user.email)
      const canDownloadResult = this.canDownloadResult.find((u) => u.email === user.email)

      if (canViewResult && canDownloadModel && canDownloadResult) {
        type = ShareType.ModelAndResult
        platform = SharePlatform.SteelspaceAndDesktop
      } else if (!canViewResult && canDownloadModel && !canDownloadResult) {
        type = ShareType.Model
        platform = SharePlatform.SteelspaceAndDesktop
      } else if (canViewResult && !canDownloadModel && !canDownloadResult) {
        type = ShareType.ModelAndResult
        platform = SharePlatform.Steelspace
      } else {
        type = ShareType.Model
        platform = SharePlatform.Steelspace
      }
      const share = new SteelspaceModelUserShare()
      share.SetShare(platform, type)
      share.user = user
      shares.push(share)
    })
    return shares
  }

  getSharesData(): Map<string, { user: ModelUserData; abilities: string }> {
    const addAbility = (
      users: ModelUserData[],
      ability: string,
      subject: string,
      abilities: Map<string, { user: ModelUserData; abilities: Map<string, Set<string>> }>
    ) => {
      users.forEach((userWithViewAbility) => {
        if (!abilities.has(userWithViewAbility.id)) {
          abilities.set(userWithViewAbility.id, {
            user: userWithViewAbility,
            abilities: new Map<string, Set<string>>(),
          })
        }

        const abilitiesOfUser = abilities.get(userWithViewAbility.id)?.abilities as Map<
          string,
          Set<string>
        >

        if (!abilitiesOfUser.has(subject)) {
          abilitiesOfUser.set(subject, new Set<string>())
        }

        const abilitiesOfUserForSubject = abilitiesOfUser.get(subject) as Set<string>
        abilitiesOfUserForSubject.add(ability)
      })
    }

    const abilitiesByUsers = new Map<
      string,
      { user: ModelUserData; abilities: Map<string, Set<string>> }
    >()
    addAbility(this.canViewModel, 'view', 'model', abilitiesByUsers)
    addAbility(this.canViewResult, 'view', 'result', abilitiesByUsers)
    addAbility(this.canDownloadModel, 'download', 'model', abilitiesByUsers)
    addAbility(this.canDownloadResult, 'download', 'result', abilitiesByUsers)
    addAbility(this.canComment, 'comment', '', abilitiesByUsers)

    const abilitiesAsStringByUser = new Map<string, { user: ModelUserData; abilities: string }>()

    abilitiesByUsers.forEach((abilitiesOfUser, userId) => {
      abilitiesAsStringByUser.set(userId, {
        user: abilitiesOfUser.user,
        abilities: [...abilitiesOfUser.abilities.entries()]
          .map(([subject, abilities]) => {
            return [...abilities.values()].join(', ') + ' ' + subject
          })
          .join('; ')
          .trimEnd(),
      })
    })

    return abilitiesAsStringByUser
  }
}
