import router from '@/router'
import {
  getAuth,
  OAuthProvider,
  signInWithCustomToken,
  signInWithPopup,
  signOut,
} from 'firebase/auth'
import { setAuthError, setAuthLoading, setSteelspaceCurrentUser } from './auth.store'
import {
  generateCustomToken,
  getCustomTokenOauth,
  getLicencingToken,
} from './requests/auth.request'

const provider = new OAuthProvider('microsoft.com')

provider.setCustomParameters({
  prompt: 'select_account',
  //tenant: 'TENANT_ID', //Commented until app is beeing registered in corporate Azure AD (fix tenant id, so that only "insider" corporate accounts can login)
})

export const loginOauth = async (authToken: string): Promise<void> => {
  try {
    const oauthToken = await getLicencingToken(authToken)

    // if custom token was received, use it to sign in
    if (oauthToken) {
      const steelspaceCustomToken = await getCustomTokenOauth(oauthToken)
      if (steelspaceCustomToken) {
        await signInWithCustomToken(getAuth(), steelspaceCustomToken)
      }
    }
  } catch (error) {
    console.log(error)
  }
}

export const login = async (idToken: string): Promise<void> => {
  try {
    const steelspaceCustomToken = await generateCustomToken(idToken)
    if (steelspaceCustomToken) {
      await signInWithCustomToken(getAuth(), steelspaceCustomToken)
    }
  } catch (error) {
    console.log(error)
  }
}

export const logout = async (): Promise<void> => {
  setAuthLoading(true)
  setSteelspaceCurrentUser(null)
  await signOut(getAuth())
  setAuthLoading(false)
  router.push('/login')
}

export async function popUpToMicrosoftAuth(): Promise<void> {
  try {
    const auth = getAuth()
    const result = await signInWithPopup(auth, provider).catch(function (error) {
      if (error.code === 'auth/account-exists-with-different-credential') {
        setAuthError(
          'Már létezik Consteel fiókod ezzel az email címmel. Kérlek használd a Consteel belépést.'
        )
      }
    })

    if (result) {
      const closedCheck = setInterval(() => {
        clearInterval(closedCheck)
        window.location.reload()
      }, 500)
    }
  } catch (error) {
    setAuthError('Hiba történt a Microsoft fiókkal történő bejelentkezés során')
    console.log(error)
  }
}

export function openAuthWindow(): void {
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : screen.width
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : screen.height

  const authWindowSize = {
    width: 400,
    height: 600,
  }

  const systemZoom = width / window.screen.availWidth
  const left = (width - authWindowSize.width) / 2 / systemZoom + dualScreenLeft
  const top = (height - authWindowSize.height) / 2 / systemZoom + dualScreenTop

  const apiKey = process.env.VUE_APP_FB_CONSTEEL_AUTH_APIKEY

  const authWindow = window.open(
    `https://auth.consteelsoftware.com/?apiKey=${apiKey}&redirect=${encodeURI(
      window.location.origin + '/sso'
    )}`,
    'Sign in with Consteel',
    `toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=${
      authWindowSize.width / systemZoom
    },height=${authWindowSize.height / systemZoom},top=${top},left=${left}`
  )

  const closedCheck = setInterval(() => {
    if (authWindow?.closed) {
      clearInterval(closedCheck)
      window.location.reload()
    }
  }, 500)
}
