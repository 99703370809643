
import { getLineLoad } from '@/modules/storm'
import { latex_units, roundToTwoDigits } from '@/services/unit.service'
import { LineLoad } from '@consteel/storm'
import DefaultLabel from './DefaultLabel.vue'
import { LabelBase, LabelContent } from './LabelBase'

export default (DefaultLabel as typeof LabelBase).extend({
  name: 'LineLoadLabel',
  computed: {
    currentMember(): LineLoad | null {
      return getLineLoad(this.model, this.objectGuid)
    },
    title(): string | undefined {
      return this.currentMember?.name
    },
    multiplicationFactor(): number {
      return this.currentMember?.multiplicationFactor || 1
    },
    content(): LabelContent[] {
      return [
        {
          name: 'X1',
          value: roundToTwoDigits(
            this.currentMember?.force1 ? this.currentMember.force1.x * this.multiplicationFactor : 0
          ),
          unit: latex_units.kn_m,
        },
        {
          name: 'Y1',
          value: roundToTwoDigits(
            this.currentMember?.force1 ? this.currentMember.force1.y * this.multiplicationFactor : 0
          ),
          unit: latex_units.kn_m,
        },
        {
          name: 'Z1',
          value: roundToTwoDigits(
            this.currentMember?.force1 ? this.currentMember.force1.z * this.multiplicationFactor : 0
          ),
          unit: latex_units.kn_m,
        },
        {
          name: 'X2',
          value: roundToTwoDigits(
            this.currentMember?.force2 ? this.currentMember.force2.x * this.multiplicationFactor : 0
          ),
          unit: latex_units.kn_m,
        },
        {
          name: 'Y2',
          value: roundToTwoDigits(
            this.currentMember?.force2 ? this.currentMember.force2.y * this.multiplicationFactor : 0
          ),
          unit: latex_units.kn_m,
        },
        {
          name: 'Z2',
          value: roundToTwoDigits(
            this.currentMember?.force2 ? this.currentMember.force2.z * this.multiplicationFactor : 0
          ),
          unit: latex_units.kn_m,
        },
      ]
    },
  },
})
